import { ErrorBoundaryProps, ErrorBoundary } from '@sentry/react';
import { Outlet } from 'react-router-dom';
import { handleError } from '../../blocks/Error';

const beforeCapture: ErrorBoundaryProps['beforeCapture'] = scope => {
    scope.setTag('boundary', 'CenteredLayout');
};

const CenteredLayout = () => (
    <div className="flex h-screen items-center justify-center">
        <ErrorBoundary beforeCapture={beforeCapture} fallback={handleError}>
            <Outlet />
        </ErrorBoundary>
    </div>
);

export default CenteredLayout;
